import * as React from "react"
import {Link} from "gatsby"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seo"

import IconPill from "../../images/icons/pill.png"
import Derrick from "../../images/hp/patient-id/derrick.png"

const HpSignUpPage = ({ location }) => {
    return (
        <>
            <HPPLayout
                location={location}
                locationHostName={location.hostname}
                jobCode="PP-AUR-US-1407 (v7.0)"
                jobCodeDate="07/23"
            >
                <Helmet>
                    <html lang="en" />
                    <link rel="canonical" href="https://www.auryxiahcp.com/hyperphosphatemia/free-trial" />
                 
                    <body id="hpp-hcp-free-trial" className="hpp" />
                </Helmet>

                <Seo
                    title="Free Trial"
                    description="Talk to a sales representative, order samples, or receive additional information about AURYXIA. Read Important Safety Information and Prescribing Information on this page."
                />

                <section id="hero" className="flood-hp col-8 col-lg-6">
                    <div>
                        <div>
                            <h1>Get your patients started with the AURYXIA Free Trial Program*</h1>
                        </div>
                    </div>
                    <div className="flex-cta-header">
                        <div id="orange-cta" className="orange-cta">
                            <div className="flex-cta">
                                <div>
                                    <p><strong>Looking for samples of AURYXIA?</strong></p>
                                    <p><Link to="/hyperphosphatemia/sign-up?request-samples=true">Request Samples</Link> for your clinically appropriate patients</p>
                                </div>
                            </div>
                        </div>
                        <span className="icon-wrap"><img src={IconPill} alt="" /></span>
                    </div>
                    <div className="flex-row">
                        <div>
                            <div id="derrick" className="mobile">
                                <img src={Derrick} alt="A male patient in a dark gray t-shirt sits with his forearms on his knees" />
                                <p>Not actual patient.</p>
                            </div>
                            <div className="flex-row">
                                <div>
                                    <p>Enrolled clinically appropriate patients will receive a FREE supply<sup>†</sup> of AURYXIA sent directly to their home, along with helpful educational resources.</p>
                                    <ol>
                                        <li>
                                            <h4>Click the button below or go to <a target="_blank" href="https://AURYXIAHCP.com/freetrial">AURYXIAHCP.com/freetrial</a> to fill out the enrollment form</h4>
                                        </li>
                                        <li>
                                            <h4>Print and fax the form to <a>833-702-3436</a></h4>
                                        </li>
                                    </ol>
                                    <p>Once your request is processed, AURYXIA will be shipped directly to your patients.</p>
                                </div>
                                <div id="derrick-desktop" className="desktop">
                                    <img src={Derrick} alt="A male patient in a dark gray t-shirt sits with his forearms on his knees" />
                                    <p>Not actual patient.</p>
                                </div>
                            </div>
                            <a className="flex-row request-btn" data-title="Free Trial" target="_blank" href="https://AURYXIAHCP.com/freetrial">ENROLL TODAY!</a>    
                        </div>
                    </div>
                    <div className="footnote-wrapper">
                        <p className="footnote"><span className="hang">*</span>For patients new to AURYXIA and who have not previously enrolled in the program. This free trial prescription is valid for one time only with no refills. There is no obligation to continue use of AURYXIA after the free trial. If AURYXIA is right for your patient, a new prescription is needed to continue treatment. Terms and conditions apply.</p>
                        <p className="footnote"><sup className="sup_margin">†</sup>Supplied in 1 bottle of 100-count 210 mg ferric iron tablets.</p>
                        <Link className="flex-row request-btn" data-title="Contact a Representative" to="/hyperphosphatemia/sign-up?request-rep=true">CONTACT A REPRESENTATIVE</Link>    

                    </div>
                </section>


            </HPPLayout>
        </>
    )
}

export default HpSignUpPage
